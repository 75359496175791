// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-gather-tsx": () => import("./../../../src/pages/gather.tsx" /* webpackChunkName: "component---src-pages-gather-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("./../../../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-program-tsx": () => import("./../../../src/pages/program.tsx" /* webpackChunkName: "component---src-pages-program-tsx" */),
  "component---src-pages-startntnu-tsx": () => import("./../../../src/pages/startntnu.tsx" /* webpackChunkName: "component---src-pages-startntnu-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

